<template>
  <div
    data-style="international-visitor-redirect-overlay"
    data-component="Global-header-IVR-Overlay"
  >
    <div data-test-id="modal-overlay" class="shader ivr" v-if="isModalOpen">
      <div class="modal-scroll">
        <dialog open="open" class="modal_component ivr">
          <section class="ivr-main-container">
            <img class="big-img-flag" :src="bigFlagImage" :alt="imageInfo" />
            <h3>
              <span>{{ supportingText }} {{ countryName }}</span>
            </h3>
            <div class="note-suggestion">
              <span>{{ note }}</span>
            </div>
            <div class="redirect-to-foreign">
              <img
                class="small-img-flag"
                :src="smallFlagImage"
                :alt="imageInfo"
              />
              <div class="site-name">
                <a :href="url">{{ foreignSiteName }}</a>
              </div>
            </div>
            <div
              data-test-id="dismiss-modal"
              class="dismiss-overlay-text"
              @click="toggleModal"
            >
              <span>{{ dismissText }}</span>
            </div>
          </section>
        </dialog>
      </div>
    </div>
    <div data-test-id="show-top-bar" v-else>
      <div v-show="!closeHeaderStrip" class="hide-site-link-contents">
        <img
          class="show-bar-small-flag"
          :src="smallFlagImage"
          :alt="imageInfo"
        />
        <div class="site-link-suggestion">
          <div>
            <span>{{ supportingText }} {{ countryName }}</span>
          </div>
          <div>
            <a :href="url" class="redirect-to-foreign">{{ foreignSiteName }}</a>
          </div>
        </div>
        <div>
          <button
            data-test-id="close-button"
            type="button"
            class="btnClose ivr"
            aria-label="close-global-modal"
            @click="close"
          >
            x
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  IVR_PREF_HIDE_SITE_LINK_DISMISS,
  IVR_PREF_VISITOR_STATUS_NEW,
  IVR_PREF_VISITOR_STATUS_RETURNING,
  SUPPORTING_TEXT_GLOBAL_REDIRECT,
  US_COUNTRY_CODE,
  IVR_IMAGE_PATH,
} from "../../util/constants";
import Cookies from "js-cookie";

export default {
  name: "InternationalVisitorRedirectOverlay",
  props: {
    countryCode: {
      type: String,
      default: US_COUNTRY_CODE,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const countryMap = this.config?.countryMap;
    const {
      url,
      note,
      imageInfo,
      countryName,
      foreignSiteName,
      dismissText,
      bigFlagImage,
      smallFlagImage,
    } = countryMap?.[this?.countryCode] ?? {};
    const cookies = this.config?.cookies ?? {};
    const applicationUri = this?.$store?.state?.header?.applicationUri;
    const bigFlagFallbackImg = `${applicationUri}${IVR_IMAGE_PATH}${this.countryCode}_big_flag.png`;
    const smallFlagFallbackImg = `${applicationUri}${IVR_IMAGE_PATH}${this.countryCode}_small_flag.png`;
    return {
      cookies,
      isModalOpen: true,
      url,
      note,
      imageInfo,
      countryName,
      foreignSiteName,
      dismissText,
      supportingText: SUPPORTING_TEXT_GLOBAL_REDIRECT,
      closeHeaderStrip: false,
      bigFlagImage: bigFlagImage || bigFlagFallbackImg,
      smallFlagImage: smallFlagImage || smallFlagFallbackImg,
    };
  },
  mounted() {
    this.setInternationalVisitorStatus();
  },
  methods: {
    /**
     * When user clicks on x button, this method is called to set
     * closeHeaderStrip to true and call setHideSiteLinkCookieStatus.
     */
    close() {
      this.closeHeaderStrip = true;
      this.setHideSiteLinkCookieStatus();
    },
    /**
     * toggles the modal on the overlay
     * flips isModelOpen value on user's action
     */
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    /**
     * This method sets IVR_PREF cookie value based on whether user is new to the session or returning
     * within the same session TTL
     * Cookies TTL is 120
     */
    setInternationalVisitorStatus() {
      const ivrPrefCookieValue = Cookies.get(
        this.cookies?.IVR_PREF_COOKIE_NAME
      );
      const date = new Date();
      date.setTime(
        date.getTime() + this.cookies?.IVR_PREF_COOKIE_TTL_SEC * 1000
      );
      // set the status of the visitor to "new" or else "returning" incase the visitor has already visited the page.
      if (!ivrPrefCookieValue) {
        Cookies.set(
          this.cookies?.IVR_PREF_COOKIE_NAME,
          IVR_PREF_VISITOR_STATUS_NEW,
          { expires: date }
        );
      } else {
        // Upon returning foreign visitor must see the top bar on the page.
        // this will remain in session
        this.isModalOpen = false;
        Cookies.set(
          this.cookies?.IVR_PREF_COOKIE_NAME,
          IVR_PREF_VISITOR_STATUS_RETURNING
        );
      }
    },
    /**
     * When user closes the showTopBar this method set the cookie
     * value to dismiss.
     */
    setHideSiteLinkCookieStatus() {
      // set "IVR_PREF" status to "dismiss" when user selects x button on the header strip
      Cookies.set(
        this.cookies?.IVR_PREF_COOKIE_NAME,
        IVR_PREF_HIDE_SITE_LINK_DISMISS
      );
    },
  },
};
</script>
