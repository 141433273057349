<template>
  <div
    data-style="recently-viewed-type-ahead-scroller"
    class="recently-viewed-tab-component"
  >
    <div class="rvi-scroller" v-if="productsSubset && productsSubset.length">
      <ul class="rvi-scroller-list">
        <li v-for="product in productsSubset" :key="product.pid">
          <div class="rvi-scroller-product-thumb">
            <a :href="product.productLink">
              <img :src="product.thumb_image" class="product-thumb" />
            </a>
          </div>
          <div class="rvi-scroller-product-info">
            <a
              class="product-name"
              :href="product.productLink"
              v-html="product.title"
            ></a>
          </div>
        </li>
      </ul>
      <template v-if="showScroller">
        <div
          class="btn-prev"
          @click="prevHandler"
          :class="canGoBackInScroller ? '' : 'hide'"
        >
          <SVGIcon :icon="'vta-prev'" />
        </div>
        <div
          class="btn-next"
          @click="nextHandler"
          :class="canGoNextInScroller ? '' : 'hide'"
        >
          <SVGIcon :icon="'vta-next'" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");

export default {
  name: "RecentlyViewedTypeAheadScroller",
  data() {
    return {
      storedProducts: [],
      maxProductsVisible: 2,
      scrollerIndex: 0,
      showScroller: true,
    };
  },
  props: {
    products: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    SVGIcon,
  },
  methods: {
    nextHandler() {
      if (this.canGoNextInScroller) {
        this.scrollerIndex += 1;
      }
    },

    prevHandler() {
      if (this.canGoBackInScroller) {
        this.scrollerIndex -= 1;
      }
    },

    resetScrollerIndex() {
      this.scrollerIndex = 0;
    },
  },
  computed: {
    canGoBackInScroller() {
      return this.scrollerIndex > 0;
    },
    canGoNextInScroller() {
      return (
        this.scrollerIndex + this.maxProductsVisible < this.products.length
      );
    },
    productsSubset() {
      return this.products.slice(
        this.scrollerIndex,
        this.scrollerIndex + this.maxProductsVisible
      );
    },
  },
};
</script>
