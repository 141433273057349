<template>
  <section
    data-style="search-bar"
    id="header-search-form"
    class="hide-on-mediumBreakPoint"
    :style="searchBarDisplayStyle"
  >
    <div
      data-component="Global-header-SearchBar"
      class="global-header-search"
      :class="{ 'overlay-visible': isOverlayVisible }"
      v-click-outside="onClickOutside"
    >
      <div class="HOM searchbar-bg-shade"></div>
      <div :class="this.isResponsive ? 'mobile-search' : ''">
        <div class="chevron-arrow-back HOD">
          <a
            href="javascript:void(0)"
            data-test-id="back-arrow"
            @click="hideMobileSearch()"
          >
            <SVGIcon :icon="'chevron-arrow-back'"></SVGIcon>
          </a>
        </div>
        <form
          id="search-form"
          method="GET"
          :action="formAction"
          v-on:submit.prevent="onSubmit($event, 'searchbar')"
        >
          <input
            type="search"
            id="search-field"
            name="words"
            aria-label="Search"
            :placeholder="placeholder"
            value=""
            v-model="query"
            autocorrect="off"
            autocomplete="off"
            ref="searchField"
            @click="showRecentSearches('searchbar', $event)"
            @focus="toggleSearchOverlay"
            @blur="toggleSearchOverlay"
            @keyup="onKeyupHandler"
            @keydown.up="onKeydownHandler('up')"
            @keydown.down="onKeydownHandler('down')"
            v-on:keyup.esc="hideSearchSuggestions"
            :tabindex="tabIndexValue"
          />
          <button aria-label="search" type="submit" :tabindex="tabIndexValue">
            <span v-html="defaultIcon"></span>
          </button>
        </form>
      </div>
      <div class="type-ahead-container" v-if="rviEnabled">
        <recently-viewed-type-ahead-tabs
          :ref="rviTabsRef"
          :query="query"
          :isOverlayVisible="isOverlayVisible"
          :isResponsive="isResponsive"
        />
        <div
          class="shader mobile-auto-suggest"
          v-show="isOverlayVisible && isResponsive"
        ></div>
      </div>
      <div class="type-ahead-container" v-else>
        <visual-type-ahead :ref="vtaRef" :query="query" />
        <div
          class="shader mobile-auto-suggest"
          v-show="isOverlayVisible && isResponsive"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VisualTypeAhead from "./VisualTypeAhead.vue";
import RecentlyViewedTypeAheadTabs from "./RecentlyViewedTypeAheadTabs.vue";
import clickOutside from "../../directives/click-outside";
import { getApplicationUri, getConfigById } from "../../util/context";
import EventBus from "../../util/event-bus";
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import breakPoints from "../../mixins/breakPoints";
import resize from "../../mixins/resize";
import noScroll from "../../mixins/noScroll";
import searchBar from "../../mixins/searchBar";
import SVGIcon from "../SVGIcon.vue";

const VTA_REFERENCE = "visualTypeAhead";
const RVI_REFERENCE = "recentlyViewedTabs";
import { addEvent } from "@js-ecom-tracking/datalayer";
Vue.use(clickOutside);

export default {
  name: "SearchBar",
  components: {
    VisualTypeAhead,
    RecentlyViewedTypeAheadTabs,
    SVGIcon,
  },
  mixins: [breakPoints, resize, noScroll, searchBar],
  data() {
    const config = this.getConfig("searchBar");
    const mobileSearchBarToggle =
      getConfigById("mobileSearchBarToggle", this) || {};
    return {
      defaultIcon:
        "<svg viewBox='0 0 21 20' role='img' version='1.1' aria-labelledby='title' class='icon-search' > <title id='title'>search</title> <desc>Click to search for products.</desc> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-99.000000, -120.000000)' > <g transform='translate(99.000000, 119.000000)'> <g transform='translate(0.000000, 0.681600)'> <g></g> <path d='M8.4307,1.7187 C4.5537,1.7187 1.4007,4.8717 1.4007,8.7487 C1.4007,12.6247 4.5537,15.7787 8.4307,15.7787 C12.3067,15.7787 15.4597,12.6247 15.4597,8.7487 C15.4597,4.8717 12.3067,1.7187 8.4307,1.7187 M8.4307,17.1787 C3.7827,17.1787 -0.0003,13.3977 -0.0003,8.7487 C-0.0003,4.1007 3.7827,0.3187 8.4307,0.3187 C13.0787,0.3187 16.8607,4.1007 16.8607,8.7487 C16.8607,13.3977 13.0787,17.1787 8.4307,17.1787' class='search-btn-fill-color' fill='#fff' ></path> </g> <polygon class='search-btn-fill-color' fill='#fff' points='19.793 20.6816 13.6 15.0496 14.541 14.0146 20.734 19.6466' ></polygon> </g> </g> </svg>",
      applicationUri: getApplicationUri(this),
      formAction: "/search/results.html",
      query: "",
      placeholder: config.placeholder,
      isResponsive: false,
      isOverlayVisible: false,
      isInputFocusedOrClicked: false,
      vtaRef: VTA_REFERENCE,
      rviEnabled: false,
      rviTabsRef: RVI_REFERENCE,
      searchValue: "",
      showSearchBar: !mobileSearchBarToggle?.isToggleActive,
      searchBarConfig: config,
      // eslint-disable-next-line no-empty-function
      addEvent: () => {},
    };
  },
  props: {
    tabIndexValue: {
      type: Number,
      default: 0,
    },
  },
  beforeMount() {
    this.rviEnabled = this.isRviEnabled();
  },
  mounted() {
    this.searchValue = this.getURLParamsValue() ? this.getURLParamsValue() : "";
    this.$refs.searchField.value = this.searchValue ? this.searchValue : "";
    EventBus.$on("setSearchFocusMobile", () => {
      if (!this.showSearchBar) {
        this.toggleSearchBar();
      }
      this.$nextTick(() => {
        if (!this.$refs?.searchField?.value) {
          this.searchValue = this.getURLParamsValue()
            ? this.getURLParamsValue()
            : "";
          this.$refs.searchField.value = this.searchValue
            ? this.searchValue
            : "";
        }
        this.$refs.searchField.focus();
      });
      setTimeout(() => {
        this.showRecentSearches("searchbar");
      }, 10);
    });
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
    if (this.isMediumBreakPoint()) {
      this.handleResponsiveState();
    }
    this.addEvent = addEvent;
    this.defaultIcon =
      !this.isResponsive && this.searchBarConfig?.searchDesktopSVG
        ? this.searchBarConfig?.searchDesktopSVG
        : this.defaultIcon;
  },
  computed: {
    searchBarDisplayStyle() {
      return this.showSearchBar ? "display: inherit" : "";
    },
  },
  methods: {
    // checks vuex store to determine if OSSA-2123 RVI test treatment is active
    isRviEnabled() {
      let activeTargetTests = this.$store
        ? this.$store.state.header.activeTargetTests
        : {};
      return (
        (browserLocalStorageAvailable() &&
          getItemFromLocalStorage("recentlyViewed") &&
          this.isMobile &&
          activeTargetTests &&
          activeTargetTests.ossa2123 &&
          activeTargetTests.ossa2123 === "rviSearchSuggestions") ||
        false
      );
    },
    /**
     * To hide mobile search overlay when clicks back arrow
     * Also emit an event to clear the search results
     */
    hideMobileSearch() {
      EventBus.$emit("showBrandsListBannerHeader", true);
      this.showSearchBar = false;
      EventBus.$emit("clearSearchResults");
    },
  },
  beforeDestroy() {
    EventBus.$off();
  },
};
</script>
