<template>
  <div
    data-style="visual-type-ahead"
    class="visual-type-ahead type-ahead-search-results"
    :class="{
      hide: hide,
      show: !hide,
      'top-search-vta': isTopSearchVtaEnabled,
    }"
  >
    <div class="c-horizontal-line"></div>

    <div
      class="design-platform"
      :class="{
        HOM: hideTitleOnMobile,
      }"
      v-if="isTopSearchVtaEnabled && showQuerySearchesTitle"
    >
      <div
        class="search-suggestions-title heading-label border-b border-shade-medium flex justify-between"
      >
        <span>{{
          showRecentSearchesTitle ? recentSearchesTitle : searchSuggestionsTitle
        }}</span>
        <a
          v-if="showClearRecentSearches && showingRecentSearches"
          href="javascript:void(0)"
          aria-label="Clear Recent Searches"
          class="HOD clear-recent-searches font-regular tracking-wider"
          @click.stop="clearRecentSearchesFromLocalStorage()"
        >
          Clear
        </a>
      </div>
    </div>

    <ul
      id="typeAhead"
      class="type-ahead"
      :class="showingRecentSearches ? 'recent-searches' : 'search-suggestions'"
    >
      <li v-if="isSearchSuggestionsEmpty">
        <a
          rel="nofollow"
          :href="searchResultsUrl(query)"
          @click="onRawInputHandler(query, $event)"
        >
          <span :data-keyword="query" class="queryKeyword query-keyword">{{
            query
          }}</span>
        </a>
      </li>
      <li
        :key="suggestion.q + '-suggestion'"
        v-for="(suggestion, index) in suggestions"
        :class="{ 'active-suggestion': index === currentSuggestion }"
      >
        <a
          rel="nofollow"
          :href="searchResultsUrl(suggestion.dq)"
          v-html="renderQueryKeyword(suggestion.dq)"
          @click="onSearchSuggestionHandler(suggestion.dq, $event)"
        />
      </li>
      <li
        class="HOM clear-recent-searches"
        v-if="showClearRecentSearches && showingRecentSearches"
      >
        <a
          href="javascript:void(0)"
          :aria-label="clearRecentSearchesTitle"
          @click.stop="clearRecentSearchesFromLocalStorage()"
          >{{ clearRecentSearchesTitle }}</a
        >
      </li>
    </ul>
    <span class="sr-only" role="alert">{{
      productsSubset && productsSubset.length
        ? (showRecentSearchesTitle
            ? recentSearchesTitle
            : searchSuggestionsTitle) + " Available"
        : ""
    }}</span>
    <div
      id="vtaScrollerContainer"
      class="HOM"
      :class="{
        'design-platform': isTopSearchVtaEnabled,
      }"
      v-if="productsSubset && productsSubset.length"
    >
      <div
        class="top-products-title"
        :class="{
          'heading-label border-b border-shade-medium': isTopSearchVtaEnabled,
        }"
      >
        {{ topProductsTitle }}
      </div>
      <div id="vtaScroller" class="vta-scroller">
        <ul id="vtaScrollerProducts" class="vta-scroller-list">
          <li
            :key="product.pid + '-scroller'"
            v-for="product in productsSubset"
            class="vta-scroller-product-cell"
          >
            <div class="vta-scroller-product-thumb">
              <a
                :href="product.url"
                @click="submitSearchAnalytics(product.title, $event)"
              >
                <img
                  :src="setThumbImage(product)"
                  class="product-thumb"
                  :alt="product.title"
                />
              </a>
            </div>
            <div class="vta-scroller-product-info">
              <a
                class="product-name"
                :href="product.url"
                v-html="product.title"
                @click="submitSearchAnalytics(product.title, $event)"
              ></a>
            </div>
          </li>
        </ul>
        <template v-if="showScroller">
          <div
            class="btn-prev"
            @click="prevHandler"
            :class="canGoBackInScroller ? '' : 'hide'"
          >
            <SVGIcon :icon="'vta-prev'" />
          </div>
          <div
            class="btn-next"
            @click="nextHandler"
            :class="canGoNextInScroller ? '' : 'hide'"
          >
            <SVGIcon :icon="'vta-next'" />
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="(showRvi || showMobileRvi) && showingRecentSearches"
      class="vta-recently-viewed-items design-platform"
      data-test-id="vta-recently-viewed-items"
      ref="vta-rvi-recommendation-wrapper"
    >
      <div class="design-platform" v-if="isRviPlacementsFetched">
        <div
          class="vta-rvi-title search-suggestions-title heading-label border-b border-shade-medium"
        >
          {{ rviTitle }}
        </div>
        <span class="sr-only" role="alert">{{
          showRvi && showingRecentSearches ? rviTitle + "Available" : ""
        }}</span>
      </div>

      <RecommendationWrapper
        repoName="global"
        @placementsFetched="placementsFetched"
        :applicationUri="applicationUri"
        :concept="concept"
        :currencyData="currencyData"
        :isMobileView="isMobile"
        :marketCode="getMarketCode"
        :pageTypeName="pageType"
        :promoVizConfig="promoVizConfig"
        :pricing="getPricingConfig"
        :productCarousel="productCarousel"
        :recommendationConfig="recommendationConfig"
        :showSeeMoreLink="showSeeMoreLink"
        :showSeedProduct="showSeedProduct"
        :gridColumnCount="rviItemsPerRow"
        data-test-id="recommendation-wrapper"
      />
    </div>
  </div>
</template>

<script>
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { mapGetters } from "vuex";
import breakPoints from "../../mixins/breakPoints";
import recommendedProducts from "../../mixins/recommendedProducts";
import subBrandSearch from "../../mixins/subBrandSearch";
import typeAhead from "../../mixins/typeAhead";
import EventBus from "../../util/event-bus";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");

import {
  DEFAULT_PAGE_TYPE_VTA_RVI,
  MIN_QUERY_INPUT_LIMIT,
  RVI_ITEMS_PER_ROW,
  RVI_MAX_VISIBLE,
} from "./../../util/constants";

export default {
  name: "VisualTypeAhead",
  mixins: [breakPoints, subBrandSearch, typeAhead, recommendedProducts],
  components: {
    SVGIcon,
    RecommendationWrapper: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "@vue-component-ecom-product-recommendations/product-recommendations"
      ).then((wrapper) => {
        return wrapper.default;
      }),
  },
  data() {
    const activeTests = this.$store?.state?.header?.activeTests ?? {};
    const vtaConfig = this.$store.state.header.config.vta;
    const { topSearchVta = {} } = vtaConfig;
    const topSearchVtaAbTestConfig = topSearchVta?.abTestConfig || {};
    const isTopSearchVtaEnabled =
      topSearchVta?.isEnabled &&
      (!topSearchVtaAbTestConfig?.isEnabled ||
        activeTests[topSearchVtaAbTestConfig?.name]?.variation ===
          topSearchVtaAbTestConfig?.activeVariant);
    const maxSuggestionsVisible = isTopSearchVtaEnabled
      ? topSearchVta?.searchSuggestions?.maxVisible || 5
      : this.isMobile
        ? vtaConfig?.maxSuggestionsMobile
        : vtaConfig?.maxSuggestionsVisible;
    const pageType = topSearchVta?.rvi?.pageType || DEFAULT_PAGE_TYPE_VTA_RVI;
    const rviItemsList =
      (browserLocalStorageAvailable() &&
        getItemFromLocalStorage("recentlyViewed")) ||
      [];
    const showSeeMoreLink = topSearchVta?.rvi?.showSeeMoreLink || false;
    const rviMaxVisible = topSearchVta?.rvi?.maxVisible || RVI_MAX_VISIBLE;
    const rviItemsPerRow = topSearchVta?.rvi?.itemsPerRow || RVI_ITEMS_PER_ROW;
    const rviTitle = topSearchVta?.rvi?.title;

    const clearRecentSearchesTitle = topSearchVta?.clearRecentSearches?.title;

    return {
      maxProductsVisible: isTopSearchVtaEnabled
        ? topSearchVta?.productSuggestions?.maxVisible || 6
        : (vtaConfig?.maxProductsVisible ?? 3),
      maxSuggestionsVisible: maxSuggestionsVisible ?? 3,
      isTopSearchVtaEnabled,
      topSearchVta,
      rviItemsList,
      pageType,
      isRviEnabled: false,
      isMobileRviEnabled: false,
      showSeedProduct: false,
      isRviPlacementsFetched: false,
      rviMaxVisible,
      rviItemsPerRow,
      rviTitle,
      clearRecentSearchesTitle,
      hideTitleOnMobile:
        !topSearchVta?.recentSearches && !topSearchVta?.popularSearches,
      showSeeMoreLink,
      // eslint-disable-next-line no-empty-function
      addEvent: () => {},
    };
  },
  computed: {
    ...mapGetters(["getMarketCode"]),
    showClearRecentSearches() {
      return (
        this.isRecentSearchesEnabled &&
        this.query?.trimLeft().length < MIN_QUERY_INPUT_LIMIT &&
        this.recentSearches?.length &&
        this.topSearchVta?.clearRecentSearches?.isEnabled
      );
    },
    showQuerySearchesTitle() {
      return (
        this.query?.trimLeft().length >= MIN_QUERY_INPUT_LIMIT ||
        (this.showingRecentSearches && this.recentSearchesTitle)
      );
    },
    showRecentSearchesTitle() {
      return (
        this.showingRecentSearches &&
        (!this.query || this.query?.trimLeft().length < MIN_QUERY_INPUT_LIMIT)
      );
    },
    isSearchSuggestionsEmpty() {
      return (
        !this.suggestions.length &&
        this.query?.trimLeft().length >= MIN_QUERY_INPUT_LIMIT
      );
    },
    /**
     * Returns flag if config rvi is enabled, query is empty and rvi items exists.
     * @returns {Boolean}
     */
    showRvi() {
      return (
        this.isRviEnabled &&
        !this.isMobile &&
        this.rviItemsList.length > 0 &&
        this.query?.trimLeft().length < MIN_QUERY_INPUT_LIMIT
      );
    },
    /**
     * Returns true if config rvi mobile is enabled, query is empty and rvi items exists.
     * @returns {Boolean}
     */
    showMobileRvi() {
      return (
        this.isMobileRviEnabled &&
        this.isMobile &&
        this.rviItemsList?.length > 0
      );
    },
    recommendationConfig() {
      const recommendationConfiguration = this.getRecommendationConfiguration;
      recommendationConfiguration.showProductName =
        this.topSearchVta?.rvi?.showProductName || false;
      recommendationConfiguration.wsiRecs.favorite.isEnabled =
        this.topSearchVta?.rvi?.isFavoriteEnabled || false;
      recommendationConfiguration.showProductPricing =
        this.topSearchVta?.rvi?.showProductPricing || false;
      recommendationConfiguration.showRecsGridTitle = false;
      return recommendationConfiguration;
    },
  },
  methods: {
    placementsFetched(data) {
      this.isRviPlacementsFetched = data?.state;
    },
  },
  props: {
    query: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const activeTests = this.$store?.state?.header?.activeTests ?? {};
    const rviConfig = this.topSearchVta?.rvi;
    const rviVtaAbTestConfig = rviConfig?.abTestConfig || {};
    const rviVtaAbTestConfigForMobile = rviConfig?.mobile?.abTestConfig || {};
    this.isRviEnabled =
      rviConfig?.enabled &&
      (!rviVtaAbTestConfig?.isEnabled ||
        activeTests[rviVtaAbTestConfig?.name]?.variation ===
          rviVtaAbTestConfig?.activeVariant);
    this.isMobileRviEnabled =
      rviConfig?.mobile?.enabled &&
      (!rviVtaAbTestConfigForMobile?.isEnabled ||
        activeTests[rviVtaAbTestConfigForMobile?.name]?.variation ===
          rviVtaAbTestConfigForMobile?.activeVariant);
    EventBus.$on("clearSearchResults", () => {
      // eslint-disable-next-line no-undefined
      this.clearSearchResults(true);
    });
  },
};
</script>
