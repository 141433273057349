<template>
  <div
    data-style="international-welcome-mat"
    data-component="Global-header-InternationalWelcomeMat"
    v-if="shouldShowInternationalWelcomeMat"
  >
    <Modal v-show="visible" v-on:close="closeModal">
      <div
        class="intlWelcomeDialogContent dialog-content"
        id="intlWelcomeDialog"
      >
        <h2>Going Global - Available in over 60 countries</h2>
        <div id="introcontent">
          <p>
            We now offer international shipping through global provider,
            Borderfree. As you shop, you will see prices in your selected
            currency. You can change where you would like to ship your items in
            the top right corner of our website.
          </p>
          <p>
            To learn more about international shipping, please visit our
            <a href="/customer-service/international-orders.html"
              >International Orders</a
            >
            page.
          </p>
        </div>
        <div class="currentSettings">
          <h4>You are currently shipping to</h4>
          <div class="currencyflag">
            <span class="flagtext"
              >{{ countryCode
              }}<template v-if="countryCode === 'CA'">*</template> in
              {{ currencyCode }} Currency</span
            >
            <a href="#" v-on:click="changeCountry">
              <img class="intl-flag" src="" alt="" />
              change
            </a>
          </div>
        </div>
        <p class="note">PLEASE NOTE:</p>
        <p>
          Some items are not eligible for international shipping. Please look
          for eligibility notifications on our product pages, or view our
          guidelines to see which items are
          <a href="/customer-service/returns-and-exceptions.html">ineligible</a
          >.
        </p>
        <p>
          All international orders must have a ship-to destination outside of
          the United States. We cannot support customers with international
          billing addresses shipping to U.S. addresses.
        </p>
        <p>
          Free Shipping promotions cannot be applied to International Orders.
        </p>
        <p>
          You can view your complete order total, including shipping fees,
          custom tariffs and taxes, during checkout.
        </p>
        <p>
          *We are sorry that we no longer ship to the Province of Quebec,
          Canada.
        </p>

        <button v-on:click="closeModal" class="cta">Continue Shopping</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../Modal.vue";
import { getCookie, setCookie } from "../../util/cookies";
import { getConfigById } from "../../util/context";

// For testing the component, change the default countryCode to anything other than "US"
// and remove the cookie "INTLWELCOME" from your browser
export default {
  name: "international_welcome_mat",
  props: {},
  components: {
    Modal,
  },
  data() {
    const { hasInternationalWelcomeMat = false } =
      getConfigById("i18n", this) ?? {};

    return {
      countryCode: "US",
      currencyCode: "USD",
      visible: false,
      hasInternationalWelcomeMat,
    };
  },
  computed: {
    shouldShowInternationalWelcomeMat() {
      return this.hasInternationalWelcomeMat;
    },
  },
  methods: {
    // eslint-disable-next-line no-empty-function
    getData() {},
    hasWelcomeMatCookie() {
      return !!getCookie("INTLWELCOME");
    },
    setWelcomeMatCookie() {
      setCookie("INTLWELCOME", true, 100);
    },
    closeModal() {
      this.visible = false;
    },
    changeCountry() {
      this.closeModal();
      const changeShippingCountry = document.getElementById(
        "changeShippingCountry"
      );
      if (changeShippingCountry) {
        changeShippingCountry.click();
      }
    },
  },
  mounted() {
    const callback = function () {
      if (!this.hasWelcomeMatCookie() && this.countryCode !== "US") {
        this.visible = true;
        this.setWelcomeMatCookie();
      }
    }.bind(this);

    // TODO: Register country change event and bind callback, once it is set up
    callback();
  },
};
</script>
