<template>
  <div data-style="list-aside" data-component="Global-header-ListAside">
    <ul class="list-aside-nav">
      <li v-for="(link, index) in aside_link_list" :key="index">
        <a
          v-if="link.text.includes('Key Rewards')"
          class="classes-nav-link"
          :href="updateKeyRewardsLink(link)"
        >
          <span v-if="link.svgBefore" class="icon_svg_before">
            <SVGIcon :icon="link.svgBefore"></SVGIcon>
          </span>
          <span class="link_label">{{ link.text }}</span>
          <span v-if="link.svgAfter" class="icon_svg_after">
            <SVGIcon :icon="link.svgAfter"></SVGIcon>
          </span>
          <span
            class="favorite-count"
            v-if="link.showCount && favoriteCount > 0"
            >({{ favoriteCount }})</span
          >
        </a>
        <a v-else class="classes-nav-link" :href="link.link">
          <span v-if="link.svgBefore" class="icon_svg_before">
            <SVGIcon :icon="link.svgBefore"></SVGIcon>
          </span>
          <span class="link_label">{{ link.text }}</span>
          <span v-if="link.svgAfter" class="icon_svg_after">
            <SVGIcon :icon="link.svgAfter"></SVGIcon>
          </span>
          <span
            class="favorite-count"
            v-if="link.showCount && favoriteCount > 0"
            >({{ favoriteCount }})</span
          >
        </a>
        <span v-if="link.hoverComponent" class="hoverOverlay" style="">
          <keep-alive>
            <component :is="link.hoverComponent"></component>
          </keep-alive>
        </span>
      </li>
      <li
        v-if="shippingCountryEnabled && !hasShippingCountryInFooter"
        class="international"
      >
        <ShippingCountry />
      </li>
    </ul>
  </div>
</template>

<script>
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import Modal from "../Modal.vue";
import ShippingCountrySelection from "../ShippingCountrySelection.vue";
import ShippingCountry from "../ShippingCountry.vue";
import { getConfigById, getKeyRewardsLink } from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import FindAStorePopOut from "./ActionLinkList/FindAStorePopOut.vue";
import { getFavoritesCount } from "@js-ecom-mfe/favorites";
import { REPONAME } from "../../util/constants";
import EventBus from "../../util/event-bus";

const fileName = "ListAside.vue";

export default {
  name: "list-aside",
  components: {
    FindAStorePopOut,
    Modal,
    ShippingCountrySelection,
    ShippingCountry,
    SVGIcon,
  },

  created() {
    const config = this.getConfig();
    this.aside_link_list = config.links;
  },

  async mounted() {
    // show the favorite's count only if a link needs it
    const showCount = this.aside_link_list.reduce(
      (result, link) => result || link.showCount,
      false
    );
    if (showCount) {
      this.favoriteCount = await getFavoritesCount();
    } else {
      this.favoriteCount = 0;
    }
    WSI.state.onChange("updateFlyoutCount", this.onUpdateFlyoutCount);
  },

  updated() {
    // update favorite count for mobile/responsive viewport
    EventBus.$emit("setFavoriteCount", this.favoriteCount);
  },

  data() {
    const {
      hasShippingCountryInFooter = false,
      shippingCountryEnabled = false,
    } = getConfigById("i18n", this) || {};

    return {
      hasShippingCountryInFooter,
      shippingCountryEnabled,
      favoriteCount: 0,
    };
  },

  methods: {
    getConfig: function () {
      const config = getConfigById("asideLinks", this);
      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `Parsing error in asideLinks config`
        );
        return {
          links: [],
        };
      }

      return {
        links: config.asideLinkList || [],
      };
    },
    onUpdateFlyoutCount(count) {
      this.favoriteCount = count;
    },
    updateKeyRewardsLink(link) {
      return getKeyRewardsLink(link);
    },
  },
};
</script>
