<template>
  <div
    data-style="recommendations-fly-out"
    class="grid-recommendations-wrapper"
  >
    <Drawer
      ref="drawerComponent"
      :showDrawer="showDrawer"
      :customClass="'flyout'"
      :direction="'right'"
      :lockScreen="true"
      :sitsInlineWith="sitsInlineWith"
      :fixedHeader="fixedHeader"
      :isResponsive="isResponsive"
      v-on:close="toggleDrawer"
      v-bind:class="{ 'mobile-flyout': isResponsive }"
    >
      <template v-slot:header>
        <button class="mobile-back-button" @click="goBack"></button>
      </template>
      <template v-slot:content>
        <div v-if="showDrawer" class="flyout-content">
          <RecommendationWrapper
            @favoriteAnalytics="addFavoriteAnalyticsEvent"
            @productFavoriteTogglerOperationsRecommendation="
              updateFavoritedRecommendationItem
            "
            applicationUri="/"
            repoName="global"
            :isMobileView="isMobile"
            :concept="concept"
            :currencyData="currencyData"
            :marketCode="marketCode"
            :pageTypeName="pageTypeName"
            :seedList="[recommendationProduct]"
            :recommendationConfig="getRecommendationConfiguration"
            :pricing="getPricingConfig"
            :productCarousel="getProductCarousel"
            :promoVizConfig="getPromoVizConfig"
            :seedProduct="seedProduct"
          />
        </div>
      </template>
      <template v-slot:footer>
        <!-- Footer Content here -->
      </template>
    </Drawer>
  </div>
</template>
<script>
import Drawer from "../drawer/Drawer.vue";
import EventBus from "../../util/event-bus";
import { getConfigById } from "../../util/context";
import recommendedProducts from "../../mixins/recommendedProducts";
import { mapActions } from "vuex";
import {
  MIN_DESKTOP_WIDTH,
  RECOMMENDATION_VARIANT,
  INLINE_RECOMMENDATIONS_BREAKPOINTS,
  PRICE_TYPE_BW_RANGE,
  US_MARKET_CODE,
  FLYOUT_CONFIG,
  RECOMMENDATIONS_CONFIG,
  PRICING_CONFIG,
  PRODUCT_CAROUSAL_CONFIG,
  PROMOVIZ_CONFIG,
} from "../../util/constants";
import { addEvent } from "@js-ecom-tracking/datalayer";

export default {
  name: "recommendations-flyout",
  components: {
    Drawer,
    RecommendationWrapper: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "@vue-component-ecom-product-recommendations/product-recommendations"
      ).then((wrapper) => {
        return wrapper.default;
      }),
  },
  mixins: [recommendedProducts],
  data() {
    const flyoutConfig =
      getConfigById("viewMoreRecommendations", this) || FLYOUT_CONFIG;
    const recommendationsConfig =
      getConfigById("recommendation", this) || RECOMMENDATIONS_CONFIG;
    const pricing = getConfigById("pricing", this) || PRICING_CONFIG;
    const productCarousel =
      getConfigById("productCarousel", this) || PRODUCT_CAROUSAL_CONFIG;
    const promoVizConfig =
      getConfigById("promotionVisibility", this) || PROMOVIZ_CONFIG;
    const currencyData = this.$store?.state?.header?.currencyData || {};
    return {
      showDrawer: false,
      flyoutConfig,
      recommendationsConfig,
      recommendationFavoriteFlyoutConfig:
        recommendationsConfig?.wsiRecs?.flyout ?? {},
      pricing,
      productCarousel,
      promoVizConfig,
      currencyData,
      sitsInlineWith: this.isResponsive ? "#header-search-form" : "#nav-main",
      fixedHeader: flyoutConfig.fixedHeader || "#sub-brand-bar",
      isResponsive: false,
      recommendationProduct: "",
      analyticsParam: "",
      concept: this.$store?.state?.header?.concept,
      pageTypeName: "",
      getPricing: {},
      getFlyoutRecommendations: {},
      recommendationFavoriteItem: {},
      seedProduct: {},
    };
  },
  methods: {
    ...mapActions([
      "currencyDataFetch",
      "setFavoritedRecommendationItem",
      "setMyStoreIsFlyoutOpen",
    ]),
    addFavoriteAnalyticsEvent(favoriteAnalyticsPayload) {
      addEvent(favoriteAnalyticsPayload);
    },
    /**
     * Emits event enableRVIPopout.
     * Toggles showDrawer value.
     * @returns {Boolean}
     */
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
      EventBus.$emit("enableRVIPopout");
    },
    /**
     * Emits event toggleRecentlyViewedDrawer.
     * Toggles showDrawer value.
     * @returns {Boolean}
     */
    goBack() {
      EventBus.$emit("toggleRecentlyViewedDrawer");
      this.showDrawer = !this.showDrawer;
    },
    // Take country and currency input from events and updates country ad currency based on cookie
    fetchCurrencyData(country, currency) {
      this.currencyDataFetch({
        selectedCountry: country,
        selectedCurrency: currency,
      });
    },
    /**
     * Passed to Favorite Flyout, after emitted by Recommendation Wrapper
     * @param {Object} recommendationFavoriteItem -- Favorite Item comes from recommendation wrapper
     */
    updateFavoritedRecommendationItem(favoritedRecommendationItem) {
      this.setFavoritedRecommendationItem(favoritedRecommendationItem);
    },
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });

    WSI.state.onChange("currencyUpdate", (event) => {
      this.fetchCurrencyData(event.selectedCountry, event.selectedCurrency);
    });

    EventBus.$on("LoadFlyoutRecommendations", (args) => {
      this.setMyStoreIsFlyoutOpen(false);
      this.recommendationProduct = args.product;
      this.analyticsParam = args.analyticsParam;
      this.seedProduct = args.seedProduct;
      this.pageTypeName = args.pageType;
      this.showDrawer = !this.showDrawer;
    });
  },
  computed: {
    /**
     * Returns true if it is mobile view.
     * @returns {Boolean}
     */
    isMobile() {
      return (
        this.$store?.state?.header?.activeProperties?.isMobile === "1" ||
        this.windowWidth < MIN_DESKTOP_WIDTH
      );
    },
    /**
     * Get market code from store.
     * Returns market code.
     * @returns {String}
     */
    marketCode() {
      let marketCodeState = this.$store?.state?.header?.market;
      if (marketCodeState === "US") {
        marketCodeState = US_MARKET_CODE;
      }
      return marketCodeState || US_MARKET_CODE;
    },
    /**
     * Create recommendation object for "RecommendationWrapper".
     * Override recsHostUrl with maskUrl if switch is enabled.
     * Returns recommendation object from brand config.
     * @returns {Object}
     */
    getRecommendationConfiguration() {
      let recommendationsHostUrl = "";
      const getUri = this.handleSeeMoreLink;
      getUri.maskUrlEnabled && getUri.maskUrl
        ? (recommendationsHostUrl = getUri.maskUrl)
        : (recommendationsHostUrl =
            this.recommendationsConfig?.wsiRecs?.recsHostUrl);
      const {
        isRecsEnabled,
        recommendationSource,
        variant = RECOMMENDATION_VARIANT,
      } = this.recommendationsConfig;
      const favorite = this.recommendationsConfig?.wsiRecs?.favorite || {};
      const breakpoints =
        this.flyoutConfig?.inlineRecommendations?.breakpoints ||
        INLINE_RECOMMENDATIONS_BREAKPOINTS;
      this.getFlyoutRecommendations = {
        isRecsEnabled,
        recommendationSource,
        wsiRecs: { breakpoints, favorite },
        variant,
      };
      this.getFlyoutRecommendations.wsiRecs.recsHostUrl =
        recommendationsHostUrl;
      return this.getFlyoutRecommendations || {};
    },
    /**
     * Create pricing object for "RecommendationWrapper".
     * Returns pricing object from brand config.
     * @returns {Object}
     */
    getPricingConfig() {
      const {
        mdmLabels,
        defaultLabels,
        showPriceTypeBetweenPriceRanges = PRICE_TYPE_BW_RANGE,
      } = this.pricing;
      const { showDiscountPercentage, showMdmPriceLabels } = this.pricing;
      this.getPricing = {
        mdmLabels,
        defaultLabels,
        showDiscountPercentage,
        showMdmPriceLabels,
        showPriceTypeBetweenPriceRanges,
      };
      return this.getPricing || {};
    },
    /**
     * Create product carousel object for "RecommendationWrapper".
     * Returns product carousel object from brand config.
     * @returns {Object}
     */
    getProductCarousel() {
      return this.productCarousel || {};
    },
    /**
     * Create promo visibility object for "RecommendationWrapper".
     * Returns promo visibility object from brand config.
     * @returns {Object}
     */
    getPromoVizConfig() {
      return this.promoVizConfig || {};
    },
  },
};
</script>
