/* eslint-disable camelcase */
/**
 * Converts an RVI service-formated product object to a Bloomreach-formatted object
 * from https://github.wsgc.com/eCommerce-Bedrock/ecom-app-shop/blob/release/src/client/components/RecentlyViewedItems.vue
 *
 * @param {object} product -- RVI formatted product to convert
 * @param {object} productId -- Product Id
 *
 * @returns {object}
 */
export const convertRVIProductToBloomreachFormat = (
  product,
  productId,
  imgUrlBase
) => {
  let BRProduct = JSON.parse(JSON.stringify(product));
  BRProduct.pid = productId;
  BRProduct.thumb_image = `${imgUrlBase}${product.imageUrl}t.jpg`;
  BRProduct.title = product.name;
  BRProduct.priceType =
    product.priceType.charAt(0).toUpperCase() +
    product.priceType.slice(1).toLowerCase();
  BRProduct.price = product.originalPrice;
  delete BRProduct.originalPrice;
  BRProduct.sale_price = product.currentPrice;
  delete BRProduct.currentPrice;
  if (product.priceRange) {
    const splitPriceRange = product.priceRange.split(" - ");
    if (
      splitPriceRange[0] === Number.parseFloat(product.currentPrice).toFixed(2)
    ) {
      BRProduct.price_range = [product.currentPrice, product.originalPrice];
      BRProduct.sale_price_range = splitPriceRange;
    } else {
      BRProduct.price_range = splitPriceRange;
    }
  } else {
    //if no price range, BR object expects one, populate ranges w/ orig & current prices
    BRProduct.price_range = [product.originalPrice, product.originalPrice];
    BRProduct.sale_price_range = [product.currentPrice, product.currentPrice];
  }
  let trackingStr = "cm_re=rvi-search-mobile";
  BRProduct.productLink = `/products/${BRProduct.pid}/?${trackingStr}`;
  return BRProduct;
};
