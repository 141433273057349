var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recently-viewed-type-ahead-tabs",class:{ hide: _vm.hide },attrs:{"data-style":"recently-viewed-type-ahead-tabs"},on:{"click":_vm.onClickInside}},[_c('div',{staticClass:"tab-header"},[_c('ul',{staticClass:"tab-buttons"},[_c('li',{class:[
          'tab-button',
          'visual-type-ahead-tab',
          { hide: _vm.vtaHidden, active: _vm.activeTab === _vm.tabs[0] },
        ],on:{"click":function($event){return _vm.switchTab(_vm.tabs[0])}}},[_c('h3',{staticClass:"inline-search-heading"},[_vm._v("Search Suggestions")])]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.vtaHidden),expression:"!vtaHidden"}],class:['tab-separator']},[_vm._v("|")]),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasStoredProducts),expression:"hasStoredProducts"}],class:[
          'tab-button',
          'recently-viewed-tab',
          { active: _vm.activeTab === _vm.tabs[1] },
        ],on:{"click":function($event){return _vm.switchTab(_vm.tabs[1])}}},[_c('h3',{staticClass:"inline-search-heading"},[_vm._v("Recently Viewed")])])])]),_vm._v(" "),_c('div',{staticClass:"tab-body"},[(_vm.activeTab === _vm.tabs[0])?[_c('visual-type-ahead',{ref:_vm.vtaRef,attrs:{"query":_vm.query},on:{"hook:mounted":_vm.vtaMounted,"hook:beforeDestroy":_vm.vtaDestroyed}})]:[_c('recently-viewed-type-ahead-scroller',{attrs:{"products":_vm.products}})]],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === _vm.tabs[1]),expression:"activeTab === tabs[1]"}],staticClass:"recently-viewed-close"},[_c('a',{staticClass:"recentlyViewedClose",attrs:{"href":"#","aria-label":"Close Suggestions"},on:{"click":_vm.onClickClose}},[_vm._v("\n      Close\n    ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRviOverlayVisible && _vm.isResponsive),expression:"isRviOverlayVisible && isResponsive"}],staticClass:"shader mobile-recently-viewed",on:{"click":_vm.onClickClose}})])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }