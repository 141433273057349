<template>
  <div
    data-style="recently-viewed-type-ahead-tabs"
    class="recently-viewed-type-ahead-tabs"
    :class="{ hide: hide }"
    v-on:click="onClickInside"
  >
    <div class="tab-header">
      <ul class="tab-buttons">
        <li
          :class="[
            'tab-button',
            'visual-type-ahead-tab',
            { hide: vtaHidden, active: activeTab === tabs[0] },
          ]"
          v-on:click="switchTab(tabs[0])"
        >
          <h3 class="inline-search-heading">Search Suggestions</h3>
        </li>
        <span v-show="!vtaHidden" :class="['tab-separator']">|</span>
        <li
          v-show="hasStoredProducts"
          :class="[
            'tab-button',
            'recently-viewed-tab',
            { active: activeTab === tabs[1] },
          ]"
          v-on:click="switchTab(tabs[1])"
        >
          <h3 class="inline-search-heading">Recently Viewed</h3>
        </li>
      </ul>
    </div>
    <div class="tab-body">
      <template v-if="activeTab === tabs[0]">
        <visual-type-ahead
          :ref="vtaRef"
          :query="query"
          @hook:mounted="vtaMounted"
          @hook:beforeDestroy="vtaDestroyed"
        />
      </template>
      <template v-else>
        <recently-viewed-type-ahead-scroller v-bind:products="products" />
      </template>
    </div>
    <div class="recently-viewed-close" v-show="activeTab === tabs[1]">
      <a
        class="recentlyViewedClose"
        v-on:click="onClickClose"
        href="#"
        aria-label="Close Suggestions"
      >
        Close
      </a>
    </div>
    <div
      class="shader mobile-recently-viewed"
      v-show="isRviOverlayVisible && isResponsive"
      v-on:click="onClickClose"
    ></div>
  </div>
</template>

<script>
import VisualTypeAhead from "./VisualTypeAhead.vue";
import RecentlyViewedTypeAheadScroller from "./RecentlyViewedTypeAheadScroller.vue";
import noScroll from "../../mixins/noScroll";
import axios from "axios";
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "../../services/const/timeouts.js";
import { productGroupEndpoint } from "../../services/const/endpoints.js";
import { getConfigById } from "../../util/context";
import { convertRVIProductToBloomreachFormat } from "../../util/recentlyViewed";
import { REPONAME } from "../../util/constants";

const FILE_NAME = "RecentlyViewedTypeAheadTabs.vue";
const VTA_REFERENCE = "visualTypeAhead";

export default {
  name: "RecentlyViewedTypeAheadTabs",
  components: {
    VisualTypeAhead,
    RecentlyViewedTypeAheadScroller,
  },
  mixins: [noScroll],
  data() {
    return {
      hide: true,
      active: false,
      vtaRef: VTA_REFERENCE,
      storedProducts: [],
      tabs: ["visual-type-ahead", "recently-viewed"],
      activeTab: "recently-viewed",
      products: [],
      rviConfig: getConfigById("recentlyViewedItems", this),
      vtaHidden: true,
    };
  },
  props: {
    query: {
      type: String,
      default: "",
    },
    isOverlayVisible: {
      type: Boolean,
      default: false,
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    vtaMounted() {
      this.$watch(`$refs.${this.vtaRef}.hide`, this.watchVtaHide);
    },
    vtaDestroyed() {
      let unwatch = this.$watch(`$refs.${this.vtaRef}.hide`, this.watchVtaHide);
      unwatch();
    },
    watchVtaHide(newVtaHideVal) {
      this.vtaHidden = newVtaHideVal;
    },
    switchTab(tabName) {
      this.activeTab = tabName;
      if (this.activeTab === this.tabs[0]) {
        // $refs is available after rendered
        this.$nextTick(function () {
          this.$refs[this.vtaRef].hide = false;
          this.$refs[this.vtaRef].fetch();
        });
      }
    },
    hideRecentlyViewedTabs() {
      this.hide = true;
      this.active = false;
      this.hideSearchSuggestions();
      // default to rvi on close
      this.resetActiveTab();
    },
    showRecentlyViewedTabs() {
      if (!this.active && this.hasStoredProducts) {
        this.hide = false;
      }
    },
    hideSearchSuggestions() {
      if (
        this.$refs[this.vtaRef] &&
        this.$refs[this.vtaRef].hideSearchResults
      ) {
        this.$refs[this.vtaRef].hideSearchResults();
      }
    },
    onClickInside(event) {
      if (
        !event.target.classList.contains("shader") &&
        !event.target.classList.contains("recently-viewed-close") &&
        !event.target.classList.contains("recentlyViewedClose") &&
        this.active === false
      ) {
        this.active = true;
      }
    },
    onClickClose() {
      this.hideRecentlyViewedTabs();
    },
    resetActiveTab() {
      this.activeTab = this.tabs[1];
    },
    // FUTURE: separate out as service in ecom-app-global
    // Taken from ecom-app-shop/src/client/services/recommendationService.js
    async getRecentlyViewedItemProduct(productName, hostURL) {
      const methodName = "getRecentlyViewedItemProduct";
      try {
        const response = await axios.get(
          `${hostURL}${productGroupEndpoint(productName)}`,
          {
            timeout: GENERIC_SERVICE_TIME_OUT,
          }
        );
        return response;
      } catch (error) {
        logger.error(REPONAME, FILE_NAME, methodName, error);
        return Promise.reject(error);
      }
    },
    /**
     * Converts an RVI service-formated product object to a Bloomreach-formatted object
     * from https://github.wsgc.com/eCommerce-Bedrock/ecom-app-shop/blob/release/src/client/components/RecentlyViewedItems.vue
     *
     * @param {object} product -- RVI formatted product to convert
     * @param {object} productId -- Product Id
     *
     * @returns {object}
     */
  },
  computed: {
    hasStoredProducts() {
      return this.storedProducts && this.storedProducts.length;
    },
    /**
     * Parent SearchBar registers click on this component as an outside click
     * and hides the search bar overlay. This property toggles a separate
     * overlay while this component is active.
     */
    isRviOverlayVisible() {
      return !this.isOverlayVisible && this.active;
    },
  },
  mounted() {
    this.storedProducts =
      browserLocalStorageAvailable() &&
      getItemFromLocalStorage("recentlyViewed")
        ? getItemFromLocalStorage("recentlyViewed")
        : [];

    if (this.storedProducts) {
      const storedProducts = this.storedProducts;
      storedProducts.forEach(async (product) => {
        try {
          const productResponse = await this.getRecentlyViewedItemProduct(
            product,
            ""
          );
          const productData = productResponse.data;
          const BRProduct = convertRVIProductToBloomreachFormat(
            productData,
            product,
            this.rviConfig.imgUrlBase
          );
          this.products = [...this.products, BRProduct];
        } catch (error) {
          logger.error(REPONAME, FILE_NAME, "mounted", error);
        }
      });
    }
  },
  watch: {
    query(newSearchQuery) {
      if (newSearchQuery.length > 2) {
        this.switchTab(this.tabs[0]);
      } else {
        this.switchTab(this.tabs[1]);
        this.vtaHidden = true;
      }
    },
    isRviOverlayVisible(newVal) {
      // watch isRviOverlayVisible and isOverlayVisible prop from parent SearchBar
      // toggle body no-scroll class appropriately
      if (
        (newVal && !document.body.classList.contains("no-scroll")) ||
        (!newVal &&
          !this.isOverlayVisible &&
          document.body.classList.contains("no-scroll"))
      ) {
        this.toggleNoScroll(newVal);
      }
    },
  },
};
</script>
