<template>
  <div
    data-style="recently-viewed-flyout"
    class="recently-viewed-flyout grid-recommendations-wrapper"
  >
    <Drawer
      ref="drawerComponent"
      :showDrawer="showDrawer"
      :customClass="'flyout'"
      direction="left"
      :lockScreen="true"
      :sitsInlineWith="sitsInlineWith"
      :isResponsive="isResponsive"
      v-on:close="toggleDrawer"
    >
      <template v-slot:content>
        <div class="recently-viewed-flyout-content flyout-content">
          <RecentlyViewedTab
            :flyoutConfig="flyoutConfig"
            @setFlyoutTitle="updateFlyoutHeader"
            :loadChildDataOnClick="loadRVIOnClick"
          />
        </div>
      </template>
    </Drawer>
  </div>
</template>
<script>
import Drawer from "../drawer/Drawer.vue";
import EventBus from "../../util/event-bus";
import RecentlyViewedTab from "./RecentlyViewedTab.vue";

export default {
  name: "recently-viewed-flyout-component",
  components: {
    Drawer,
    RecentlyViewedTab,
  },
  props: {
    flyoutConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showDrawer: false,
      sitsInlineWith: this.flyoutConfig?.sitsInlineWith || "#nav-main",
      isResponsive: false,
      isBestSellers: false,
      flyoutHeader: "Recently Viewed",
      loadRVIOnClick: false,
    };
  },
  computed: {
    // Returns title based on RVI/Best Sellers/Pzn Campaign
    getFlyoutHeader() {
      return this.isBestSellers ? "Best Sellers" : this.flyoutHeader;
    },
    bestSellersTitle() {
      return this.getFlyoutHeader === "Best Sellers"
        ? "best-sellers-title"
        : "";
    },
  },
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    // Listens to "setFlyoutTitle" event, to update flag for header change
    updateFlyoutHeader(title) {
      this.isBestSellers = title === "Best Sellers";
      this.flyoutHeader = title;
    },
  },
  mounted() {
    EventBus.$on("toggleRecentlyViewedDrawer", () => {
      this.showDrawer = !this.showDrawer;
      this.loadRVIOnClick = true;
    });
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>
