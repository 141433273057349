import axios from "axios";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { countryDetailsEndpoint } from "./const/endpoints";

const fileName = "setCountry.js";

/**
 * Makes call to set Country end point to fetch the Countries data for that international shipping
 * @param {String} path -- end point for set country
 * @returns {Promise} A promise returned by axios.get
 */
export const getCountryDialogData = async () => {
  try {
    const response = await axios.get(countryDetailsEndpoint(), {
      timeout: GENERIC_SERVICE_TIME_OUT,
    });
    return response;
  } catch (e) {
    logger.error(REPONAME, fileName, "getCountryDialogData", e);
    return Promise.reject(e);
  }
};
