<template>
  <span data-style="shipping-country" class="shipping-country-container">
    <a
      class="shipping-country-anchor"
      :href="countryDetailsEndpoint"
      :aria-label="`Ship To ${countryCode.toUpperCase()}`"
      id="changeShippingCountry"
      data-testid="shipping-country-anchor"
      @click.prevent="openShippingModal"
      ref="shipToLinkRef"
    >
      <span
        data-testid="shipping-country-flag"
        :class="`flag flag-${countryCode.toLowerCase()}`"
      />
    </a>
    <Modal v-show="isModalOpen" @close="toggleModal" @keyup.esc="toggleModal">
      <ShippingCountrySelection
        :innerContent="innerContent"
        :shippingCountry="countryCode"
        @close="toggleModal"
        @updateCountryCode="updateCountryCode"
      />
    </Modal>
  </span>
</template>

<script>
import Cookies from "js-cookie";
import EventBus from "../util/event-bus";
import Modal from "./Modal.vue";
import ShippingCountrySelection from "./ShippingCountrySelection.vue";
import { countryDetailsEndpoint } from "../services/const/endpoints";

import { getConfigById } from "../util/context";
import { getCountryDialogData } from "../services/setCountry";
import { logger } from "@js-ecom-mfe/logger";
import {
  DEFAULT_INTERNATIONAL_COOKIE_VALUE,
  REPONAME,
} from "../util/constants.js";

const fileName = "ShippingCountry.js";

export default {
  name: "ShippingCountry",
  components: {
    Modal,
    ShippingCountrySelection,
  },

  data() {
    return {
      countryCode: "",
      innerContent: "",
      isModalOpen: false,
      countryDetailsEndpoint: countryDetailsEndpoint(),
    };
  },

  methods: {
    fetchCountryDialogData() {
      if (!this.innerContent) {
        try {
          getCountryDialogData(countryDetailsEndpoint()).then((response) => {
            this.innerContent = response.data;

            EventBus.$emit("initialOpenShippingModal");
          });
        } catch (error) {
          logger.error(REPONAME, fileName, "fetchCountryDialogData", error);
        }
      }
    },

    openShippingModal() {
      this.toggleModal();
      this.fetchCountryDialogData();
    },

    setCountryCode() {
      const cookies = getConfigById("cookies", this);
      let internationalCookie = Cookies.get(cookies.international);

      if (!internationalCookie) {
        internationalCookie = DEFAULT_INTERNATIONAL_COOKIE_VALUE;
        Cookies.set(cookies.international, internationalCookie);
        // when not in international mode we don't need country name cookie
        Cookies.remove(cookies.internationalCountryName);
      }
      this.countryCode = internationalCookie.split("-")[0];
    },

    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
      this.$refs.shipToLinkRef?.focus();
    },

    updateCountryCode(countryCode) {
      this.countryCode = countryCode;
    },
  },

  created() {
    this.setCountryCode();
  },
};
</script>
