<template>
  <div
    data-style="recently-viewed-tab"
    class="recently-viewed-tab"
    data-test-id="recently-viewed-tab"
    :class="{
      'loading-spinner': handleLoader,
    }"
  >
    <div
      class="tab-header flyout-tab-header"
      v-if="pznTriggeredCampaigns.isActive"
    >
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="tab.id"
          @click="setSelectedTab(index, tab.id)"
          :class="{ 'active-tab-header': selectedTab.index === index }"
          :data-test-id="`recently-viewed-tab-${tab.id}`"
        >
          <h3>{{ tab.title }}</h3>
        </li>
      </ul>
    </div>
    <div class="tab-content" v-if="isMobileView && loadChildDataOnClick">
      <div v-if="selectedTab.index === RECENTLY_VIEWED_INDEX">
        <RecommendationWrapper
          @favoriteAnalytics="addFavoriteAnalyticsEvent"
          @onSeeMoreClickToRVIPopOut="openRecommendations"
          @placementsFetched="handleRviRecsLoader"
          @productFavoriteTogglerOperationsRecommendation="
            updateFavoritedRecommendationItem
          "
          @setFlyoutTitle="setFlyoutTitle"
          repoName="global"
          :applicationUri="applicationUri"
          :concept="concept"
          :currencyData="currencyData"
          :fallbackPlacementMessage="getFallbackPlacementMessage"
          :gridColumnCount="gridColumnCount"
          :isMobileView="isMobileView"
          :marketCode="getMarketCode"
          :pageTypeName="getRVIPageId"
          :pricing="getPricingConfigForRecs"
          :productCarousel="getRVIProductCarousel"
          :promoVizConfig="getPromoVizConfiguration"
          :recommendationConfig="getRviFlyoutRecommendationConfig"
          :rviList="[]"
          :showSeeMoreLink="showSeeMoreLink"
          :showSeedProduct="showSeedProduct"
          :isUiUserNameCookieEnabled="isUserNameCookieEnabled"
          data-test-id="recommendation-wrapper"
        />
      </div>
      <div v-else data-test-id="pznTriggeredCampaigns">
        <RecommendationWrapper
          @favoriteAnalytics="addFavoriteAnalyticsEvent"
          @onSeeMoreClickToRVIPopOut="openRecommendations"
          @placementsFetched="handleRviRecsLoader"
          @productFavoriteTogglerOperationsRecommendation="
            updateFavoritedRecommendationItem
          "
          @setFlyoutTitle="setFlyoutTitle"
          repoName="global"
          :applicationUri="applicationUri"
          :concept="concept"
          :currencyData="currencyData"
          :fallbackPlacementMessage="getFallbackPlacementMessage"
          :gridColumnCount="gridColumnCount"
          :isMobileView="isMobileView"
          :marketCode="getMarketCode"
          :pageTypeName="getRVIPageId"
          :pricing="getPricingConfigForRecs"
          :productCarousel="getRVIProductCarousel"
          :promoVizConfig="getPromoVizConfiguration"
          :recommendationConfig="getRviFlyoutRecommendationConfig"
          :rviList="[]"
          :showSeeMoreLink="showSeeMoreLink"
          :showSeedProduct="showSeedProduct"
          :isUiUserNameCookieEnabled="isUserNameCookieEnabled"
          data-test-id="recommendation-wrapper"
        />
      </div>
      <p class="api-error-message" v-html="getApiErrorMessage"></p>
    </div>
  </div>
</template>
<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import breakPoints from "../../mixins/breakPoints";
import {
  defaultCampaign,
  defaultConfig,
  getCampaignConfigById,
  getCampaignsWithProductDetails,
  isEnabledByConfig,
} from "../../helpers/pznTriggeredCampaignsHelper";
import EventBus from "../../util/event-bus";
import { getBrandConcept, getConfigById } from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import { mapActions, mapGetters } from "vuex";
import {
  saveItemToSessionStorage,
  getItemFromLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import recommendedProducts from "../../mixins/recommendedProducts";
import {
  INLINE_RECOMMENDATIONS_BREAKPOINTS,
  FLYOUT_CONFIG,
  MIN_DESKTOP_WIDTH,
  PRICING_CONFIG,
  PRICE_TYPE_BW_RANGE,
  PRODUCT_CAROUSAL_CONFIG,
  PROMOVIZ_CONFIG,
  RECOMMENDATIONS_CONFIG,
  RECOMMENDATION_VARIANT,
  REPONAME,
} from "../../util/constants";

const fileName = "RecentlyViewedTab.vue";
export default {
  name: "RecentlyViewedTab",
  components: {
    RecommendationWrapper: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "@vue-component-ecom-product-recommendations/product-recommendations"
      ).then((wrapper) => {
        return wrapper.default;
      }),
  },
  data() {
    const recsConfig =
      getConfigById("recommendation", this) || RECOMMENDATIONS_CONFIG;
    const flyoutConfig =
      getConfigById("viewMoreRecommendations", this) || FLYOUT_CONFIG;
    const profileConfig = getConfigById("profile", this) ?? {};
    return {
      addEvent: () => ({}),
      applicationUri: this.$store?.state?.header?.applicationUri,
      brandConcept: "",
      campaign: defaultCampaign,
      concept: this.$store?.state?.header?.concept,
      currencyData: this.$store?.state?.header?.currencyData || {},
      flyoutConfig,
      gridColumnCount: flyoutConfig.gridColumnCount || 1,
      isMobileView:
        this.$store?.state?.header?.activeProperties?.isMobile === "1" ||
        this.windowWidth < MIN_DESKTOP_WIDTH,
      pricing: getConfigById("pricing", this) || PRICING_CONFIG,
      productCarousel:
        getConfigById("productCarousel", this) || PRODUCT_CAROUSAL_CONFIG,
      promoVizConfig:
        getConfigById("promotionVisibility", this) || PROMOVIZ_CONFIG,
      product: {},
      pznTriggeredCampaigns: {
        config: defaultConfig,
        id: "PZN",
        isActive: false,
      },
      recsConfig,
      rviConfig: {},
      selectedTab: {
        id: "RVI",
        index: 0,
      },
      showSeedProduct: false,
      tabs: [
        {
          id: "RVI",
          title: "Recently Viewed",
        },
        {
          id: "PZN",
          title: "SKU Triggered",
        },
      ],
      showRVILoader: true,
      recsApiError: false,
      isUserNameCookieEnabled: profileConfig?.isUiUserNameCookieEnabled ?? true,
    };
  },
  props: {
    loadChildDataOnClick: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [breakPoints, recommendedProducts],
  methods: {
    ...mapActions([
      "currencyDataFetch",
      "setFavoritedRecommendationItem",
      "setPznTriggeredCampaign",
    ]),
    ...mapGetters(["getPznTriggeredCampaign"]),
    addFavoriteAnalyticsEvent(favoriteAnalyticsPayload) {
      if (this.addEvent) {
        this.addEvent(favoriteAnalyticsPayload);
      }
    },
    openRecommendations(product, analyticsParam) {
      EventBus.$emit("toggleRecentlyViewedDrawer");
      product.seedLink = `${
        product.productLink.split("?")[0]
      }?cm_src=RVI-MoreLikeThis-Seed&${analyticsParam}`;
      const emitObject = {
        config: this.flyoutConfig,
        product: product.pid,
        location: "shop",
        state: this.showSeeMore,
        index: this.productIndex,
        pageType: this.getPageType(analyticsParam.split("=")[1]),
        analyticsParam: analyticsParam,
      };
      delete product["recommendationDetails"];
      product.priceSet = {
        lowSellingPrice: product?.sale_price_range[0],
        highSellingPrice: product?.sale_price_range[1],
        lowRegularPrice: product?.price_range[0],
        highRegularPrice: product?.price_range[1],
      };
      this.product = product;
      emitObject.seedProduct = product;
      EventBus.$emit("LoadFlyoutRecommendations", emitObject);
      this.inlineRecommendationsCarouselAnalytics();
    },
    inlineRecommendationsCarouselAnalytics() {
      this.addEvent({
        category:
          this.selectedTab.id === "RVI"
            ? "recently-viewed"
            : "pzn triggered carousel",
        item: this.selectedTab.id === "RVI" ? "see-more" : "see more",
        groupId: this.product.pid,
      });
    },
    setDefaultPznTab() {
      if (this.campaign?.products.length) {
        this.setSelectedTab(1, "PZN");
      }
    },
    setFlyoutTitle(title) {
      this.$emit("setFlyoutTitle", title);
    },
    setSelectedTab(index, id) {
      this.selectedTab = { index, id };
      this.rviConfig["cmSrc"] = this.selectedTab.id;
      this.setFlyoutTitle(
        this.selectedTab.id === "RVI" ? "Recently Viewed" : this.campaign.name
      );
    },
    setPznTriggeredCampaignsTabTitle() {
      const pznTriggeredTab = this.tabs.find((tab) => {
        return tab.id === this.pznTriggeredCampaigns.id;
      });
      pznTriggeredTab.title = this.campaign.name;
    },
    async showCampaigns() {
      const methodName = "showCampaigns";
      try {
        if (
          !this.isMediumBreakPoint() ||
          !isEnabledByConfig(
            this.pznTriggeredCampaigns.config,
            this.$store,
            true
          )
        ) {
          return;
        }
        this.campaign = await getCampaignsWithProductDetails(
          this.pznTriggeredCampaigns.config,
          this.brandConcept,
          this.$store
        );
        this.pznTriggeredCampaigns.isActive = this.showTabs();
        this.saveNotificationEnabledStatus();
      } catch (error) {
        logger.error(REPONAME, fileName, methodName, error);
      }
    },
    saveNotificationEnabledStatus() {
      const campaignConfig = getCampaignConfigById(
        this.pznTriggeredCampaigns.config.campaigns,
        this.campaign?.id
      );
      const mobileNotificationEnabled =
        campaignConfig?.mobileNotificationEnabled &&
        this.campaign?.products.length > 0;
      saveItemToSessionStorage(
        "pznTriggeredCampaigns",
        "mobileNotificationEnabled",
        mobileNotificationEnabled
      );
      EventBus.$emit("pznMobileNotificationEnabledStatusChanged");
    },
    showTabs() {
      if (this.campaign?.products.length) {
        this.setPznTriggeredCampaignsTabTitle();
        EventBus.$emit("setPznTriggeredCampaignActive", this.campaign.name);
        return true;
      }
      return false;
    },
    // Take country and currency input from events and updates country ad currency based on cookie
    fetchCurrencyData(country, currency) {
      this.currencyDataFetch({
        selectedCountry: country,
        selectedCurrency: currency,
      });
    },
    handleRviRecsLoader(data) {
      this.recsApiError = !data.state;
      this.showRVILoader = false;
    },
    /**
     * Passed to Favorite Flyout, after emitted by Recommendation Wrapper
     * @param {Object} recommendationFavoriteItem -- Favorite Item comes from recommendation wrapper
     */
    updateFavoritedRecommendationItem(favoritedRecommendationItem) {
      this.setFavoritedRecommendationItem(favoritedRecommendationItem);
    },
  },
  computed: {
    ...mapGetters(["getMarketCode"]),
    /**
     * Returns API error message if the API call fails.
     * @returns {String}
     */
    getApiErrorMessage() {
      return this.recsApiError
        ? this.flyoutConfig?.apiErrorMessage || FLYOUT_CONFIG?.apiErrorMessage
        : "";
    },
    /**
     * Create fallback object for "RecommendationWrapper".
     * Returns fallback message object from config if the pageId is bestsellers.
     * @returns {Object}
     */
    getFallbackPlacementMessage() {
      const fallbackPlacementMessageObject = {
        enabled: false,
        message: "",
      };
      return this.getRVIPageId === "GLOBALRVIBESTSELLERS"
        ? this.flyoutConfig?.fallbackPlacementMessage ||
            FLYOUT_CONFIG?.fallbackPlacementMessage
        : fallbackPlacementMessageObject;
    },
    /**
     * Create pricing object for "RecommendationWrapper".
     * Returns pricing object from brand config.
     * @returns {Object}
     */
    getPricingConfigForRecs() {
      const {
        mdmLabels,
        defaultLabels,
        showPriceTypeBetweenPriceRanges = PRICE_TYPE_BW_RANGE,
      } = this.pricing;
      const { showDiscountPercentage, showMdmPriceLabels } = this.pricing;
      this.getPricing = {
        mdmLabels,
        defaultLabels,
        showDiscountPercentage,
        showMdmPriceLabels,
        showPriceTypeBetweenPriceRanges,
      };
      return this.getPricing;
    },
    /**
     * Create promo visibility object for "RecommendationWrapper".
     * Returns promo visibility object from brand config.
     * @returns {Object}
     */
    getPromoVizConfiguration() {
      return this.promoVizConfig;
    },
    /**
     * Returns recently viewed or bestsellers based on pagetype.
     * @returns {String}
     */
    getRVIPageId() {
      return getItemFromLocalStorage("recentlyViewed")
        ? (this.pageType = "GLOBALRVI")
        : (this.pageType = "GLOBALRVIBESTSELLERS");
    },
    /**
     * Create product carousel object for "RecommendationWrapper".
     * Returns product carousel object from brand config.
     * @returns {Object}
     */
    getRVIProductCarousel() {
      return this.productCarousel;
    },
    /**
     * Create recommendation object for "RecommendationWrapper".
     * Override recsHostUrl with maskUrl if switch is enabled.
     * Returns recommendation object from brand config.
     * @returns {Object}
     */
    getRviFlyoutRecommendationConfig() {
      let recsHostUrl = "";
      const getUrl = this.handleSeeMoreLink;
      getUrl.maskUrlEnabled && getUrl.maskUrl
        ? (recsHostUrl = getUrl.maskUrl)
        : (recsHostUrl =
            this.recsConfig?.wsiRecs?.recsHostUrl ||
            `${this.applicationUri}${RECOMMENDATIONS_CONFIG?.wsiRecs?.recsHostUrl}`);
      const {
        isRecsEnabled,
        recommendationSource,
        variant = RECOMMENDATION_VARIANT,
      } = this.recsConfig;
      const favorite = this.recsConfig?.wsiRecs?.favorite || {};
      const breakpoints =
        this.flyoutConfig?.inlineRecommendations?.breakpoints ||
        INLINE_RECOMMENDATIONS_BREAKPOINTS;
      this.getRecommendations = {
        isRecsEnabled,
        recommendationSource,
        wsiRecs: { breakpoints, favorite },
        variant,
      };
      this.getRecommendations.wsiRecs.recsHostUrl = recsHostUrl;
      return this.getRecommendations;
    },
    showSeeMoreLink() {
      return this.recsConfig?.showSeeMoreLink
        ? true
        : this.recsConfig?.showSeeMoreLink === false
          ? false
          : RECOMMENDATIONS_CONFIG.showSeeMoreLink;
    },
    handleLoader() {
      return this.showRVILoader;
    },
  },
  created() {
    this.RECENTLY_VIEWED_INDEX = 0;
    this.brandConcept = getBrandConcept(this);
    this.rviConfig = getConfigById("recentlyViewedItems", this) || {};
    this.rviConfig["cmSrc"] = this.selectedTab.id;
    this.pznTriggeredCampaigns.config =
      getConfigById("pznTriggeredCampaigns", this) || {};
  },
  async mounted() {
    this.addEvent = addEvent;
    EventBus.$on("mediumBreakPoint", this.showCampaigns);
    await this.showCampaigns();
    this.setDefaultPznTab();
    WSI.state.onChange("currencyUpdate", (event) => {
      this.fetchCurrencyData(event.selectedCountry, event.selectedCurrency);
    });
  },
};
</script>
